import { Button, ButtonProps } from '@mantine/core'
import { MouseEvent } from 'react'
import { cabinetColors, cabinetSizes } from 'utils/cabinetTheme'

export function AccentButton(props: ButtonProps & { onClick?: () => void; onContextMenu?: (event: MouseEvent) => void }) {
  return (
    <Button
      {...props}
      onContextMenu={props.onContextMenu}
      radius={cabinetSizes.radius}
      styles={{
        root: {
          border: `0px solid ${cabinetColors.border}`,
          background: cabinetColors.accent,
          color: cabinetColors.black,
          boxShadow: 'none',
          minHeight: cabinetSizes.buttonHeight,
          '&:hover': {
            background: cabinetColors.black,
            color: cabinetColors.accent,
          },
          '&[data-loading="true"] svg[fill]': {
            fill: cabinetColors.black,
          },
          '&[data-loading="true"]:hover svg[fill]': {
            fill: cabinetColors.accent,
          },
        },
      }}
    ></Button>
  )
}
