import { Button } from '@mantine/core'
import { cabinetColors } from 'utils/cabinetTheme'

export const linkToTelegraSupport = `https://t.me/vinglasupport`

export function VinglaSupport({ white }: { white?: boolean }) {
  return (
    <a
      // css={{
      //   border: `${colors.blue} 2px solid`,
      //   background: 'white',
      //   borderRadius: 2,
      //   padding: '2px 8px 3px 8px',
      //   color: colors.blue,
      //   textDecoration: 'none',
      // }}
      href={linkToTelegraSupport}
      target={'_blank'}
    >
      <Button
        radius={2}
        styles={{
          root: {
            background: white ? cabinetColors.white : cabinetColors.black,
            color: white ? cabinetColors.black : cabinetColors.white,
            '&:hover': {
              background: white ? cabinetColors.black : cabinetColors.white,
              color: white ? cabinetColors.white : cabinetColors.black,
            },
          },
        }}
        compact
      >
        ПОДДЕРЖКА
      </Button>
    </a>
  )
}
