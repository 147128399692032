import {
  ButtonProps,
  DividerProps,
  MantineThemeOverride,
  MenuProps,
  ModalProps,
  PaperProps,
  TextInputProps,
  TextProps,
  TitleProps,
} from '@mantine/core'
import { breakpoints } from 'config/mq'

export const cabinetColors = {
  placeholder: '#9C9898',
  background: '#F9F9F9',
  accent: '#ADFF00',
  border: '#E1E2E4',
  black: '#333',
  white: '#fff',
  blue: '#1155CC',
  close: '#EFEFEF',
  paperInner: 'rgb(17, 85, 204, 0.1)',
}

const backgroundBlurColor = cabinetColors.background // 'rgba(255, 255, 255, 0.85)'
const backdropFilter = 'blur(10px)'
export const cabinetSizes = {
  icon: 25,
  radius: 16,
  pageTop: 40,
  headlineBottom: 24,
  buttonHeight: 42,
  smallButtonHeight: 34,
  inputHeight: 42,
  labelSize: 16,
  labelMarginBottom: 6,
  inputBorderSize: 1,
}
export const cabinetShadows = {
  input: '0 3px 4px 1px rgba(0, 0, 0, 0.03)',
  paper: '0 1px 10px 0px rgba(0, 0, 0, 0.08)',
}

const ButtonDefaultProps: Partial<ButtonProps> = {
  radius: cabinetSizes.radius,
}
const PaperDefaultProps: Partial<PaperProps> = {
  radius: cabinetSizes.radius,
  shadow: cabinetShadows.paper,
}
const MenuDefaultProps: Partial<MenuProps> = {
  radius: cabinetSizes.radius,
}
const TextDefaultProps: Partial<TextProps> = {
  color: cabinetColors.black,
}
const TitleDefaultProps: Partial<TitleProps> = {
  style: {
    fontWeight: 600,
    color: cabinetColors.black,
  },
}
const DividerDefaultProps: Partial<DividerProps> = {
  color: cabinetColors.border,
}
const TextInputDefaultProps: Partial<TextInputProps> = {}
const ModalDefaultProps: Partial<ModalProps> = {
  overlayOpacity: 1,
}

const textInputStyles: TextInputProps['styles'] = {
  input: {
    boxShadow: cabinetShadows.input,
    borderRadius: cabinetSizes.radius,
    border: `${cabinetSizes.inputBorderSize}px solid ${cabinetColors.border}`,
    minHeight: cabinetSizes.inputHeight,
  },
  label: {
    fontWeight: 700,
    fontSize: cabinetSizes.labelSize,
    marginBottom: cabinetSizes.labelMarginBottom,
  },
}

export const cabinetTheme = ({}: {}): MantineThemeOverride => ({
  fontFamily: 'Manrope',
  fontFamilyMonospace: 'Manrope',
  breakpoints,
  loader: 'dots',
  // activeStyles: { transform: 'scale(0.95)' },
  focusRing: 'never',
  // respectReducedMotion: true,
  cursorType: 'pointer',

  components: {
    Switch: {
      styles: {
        input: {
          background: cabinetColors.white,
          boxShadow: cabinetShadows.input,
          border: `1px solid ${cabinetColors.border}`,
          '&:checked': {
            backgroundColor: cabinetColors.white,
            border: `1px solid ${cabinetColors.border}`,
          },
          '&:before': {
            backgroundColor: cabinetColors.border,
            border: 'none',
          },
          '&:checked:before': {
            backgroundColor: cabinetColors.accent,
            border: 'none',
          },
        },
      },
    },
    Modal: {
      defaultProps: ModalDefaultProps,
      styles: {
        close: {
          background: cabinetColors.close,
          color: cabinetColors.placeholder,
          borderRadius: 1000,
          width: 42,
          height: 42,
          '&:hover': {
            color: cabinetColors.close,
            background: cabinetColors.placeholder,
          },
        },
        modal: {
          padding: `${32}px !important`,
        },
        title: {
          fontSize: 24,
          fontWeight: 600,
          textTransform: 'uppercase',
        },
        overlay: {
          backdropFilter: backdropFilter,
          backgroundColor: `${backgroundBlurColor} !important`,
        },
        root: {
          boxShadow: cabinetShadows.paper,
        },
      },
    },
    Text: {
      defaultProps: TextDefaultProps,
    },
    Title: {
      defaultProps: TitleDefaultProps,
    },
    Paper: {
      defaultProps: PaperDefaultProps,
    },
    Divider: {
      defaultProps: DividerDefaultProps,
    },
    Menu: {
      styles: {
        dropdown: {
          backdropFilter: backdropFilter,
          background: `${backgroundBlurColor} !important`,
          boxShadow: cabinetShadows.paper,
        },
      },
      defaultProps: MenuDefaultProps,
    },
    MenuItem: {
      defaultProps: {},
    },
    Drawer: {
      styles: {
        overlay: {
          backdropFilter: backdropFilter,
          backgroundColor: backgroundBlurColor,
        },
      },
    },

    Button: {
      defaultProps: ButtonDefaultProps,
      styles: {
        root: {},
      },
    },

    TextInput: {
      defaultProps: TextInputDefaultProps,
      styles: {
        ...textInputStyles,
      },
    },
    NumberInput: {
      defaultProps: TextInputDefaultProps,
      styles: {
        ...textInputStyles,
      },
    },
    Textarea: {
      defaultProps: TextInputDefaultProps,
      styles: {
        ...textInputStyles,
      },
    },
  },
})
