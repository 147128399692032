import { isProduction } from './isProduction'

export function getPathToFile({
  fileName,
  folderName,
  withVinglaHost = false,
}: {
  fileName: string
  folderName: null | string
  withVinglaHost?: boolean
}) {
  const vinglaLink = 'https://vingla.ru'
  return `${withVinglaHost ? vinglaLink : isProduction ? '' : vinglaLink}/images/${
    folderName ? `${folderName}/` : ''
  }${fileName}` // `${isProduction ? '/images' : '/api/files'}/${fileName}` //
}

export function getPathToFont({ fontName, folderName }: { fontName: string; folderName: string }) {
  return `${isProduction ? '' : 'https://vingla.ru'}/fonts/${folderName}/${fontName}` // `${isProduction ? '/images' : '/api/files'}/${fileName}` //
}
