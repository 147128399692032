import { colors } from './styles'

import { Text } from '@mantine/core'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { cabinetColors } from './cabinetTheme'

export function VINGLA({
  white,
  small,
  radius,
  padding,
}: {
  white?: boolean
  small?: boolean
  radius?: string | number
  padding?: string | number
}) {
  const iconColor = white ? colors.blue : 'white'
  const router = useRouter()
  const width = 24
  return (
    <Link href={`/panel/`}>
      <span
        css={{
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          gap: 6,
          '&:hover': {
            opacity: 0.75,
          },
        }}
      >
        <svg
          css={{
            width,
            height: width,
            display: 'block',
          }}
          width="73"
          height="73"
          viewBox="0 0 73 73"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.792969 16.793C0.792969 7.95641 7.95641 0.792969 16.793 0.792969H56.793C65.6295 0.792969 72.793 7.95641 72.793 16.793V56.793C72.793 65.6295 65.6295 72.793 56.793 72.793H16.793C7.95641 72.793 0.792969 65.6295 0.792969 56.793V16.793Z"
            fill={cabinetColors.accent}
          />
          <path
            d="M31.315 33.1992C31.7456 33.0884 32.0514 32.7067 32.0655 32.2624L32.8128 8.69508C32.8495 7.53904 34.4928 7.35755 34.7808 8.47773L40.6532 31.3139C40.764 31.7445 41.1457 32.0502 41.59 32.0643L65.1573 32.8117C66.3134 32.8483 66.4948 34.4916 65.3747 34.7797L42.5385 40.6521C42.1079 40.7628 41.8021 41.1445 41.7881 41.5889L41.0407 65.1562C41.004 66.3122 39.3608 66.4937 39.0727 65.3735L33.2003 42.5373C33.0896 42.1068 32.7079 41.801 32.2635 41.7869L8.69624 41.0395C7.54019 41.0029 7.35871 39.3596 8.47888 39.0716L31.315 33.1992Z"
            fill={cabinetColors.black}
          />
        </svg>

        {/* 
        <svg
          css={{
            display: 'block',
            width: 20,
            height: 20,
          }}
          width="595"
          height="600"
          viewBox="0 0 595 600"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M244.033 58.5828C244.033 58.5828 286.413 182.302 349.397 222.444C412.166 262.45 541.163 248.547 541.163 248.547"
            stroke={iconColor}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M351.306 546.163C351.306 546.163 337.847 416.081 378.167 353.211C418.35 290.555 541.27 249.033 541.27 249.033"
            stroke={iconColor}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M243.547 58.6897C243.547 58.6897 257.005 188.772 216.685 251.642C176.503 314.298 53.5823 355.82 53.5823 355.82"
            stroke={iconColor}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M350.82 546.27C350.82 546.27 308.44 422.551 245.456 382.409C182.687 342.403 53.6899 356.306 53.6899 356.306"
            stroke={iconColor}
            stroke-width="45"
            stroke-linecap="round"
          />
          <path
            d="M418.873 15.8553C418.873 15.8553 433.786 59.3911 455.95 73.5168C478.038 87.5944 523.43 82.7022 523.43 82.7022"
            stroke={iconColor}
            stroke-width="25"
            stroke-linecap="round"
          />
          <path
            d="M456.622 187.431C456.622 187.431 451.886 141.656 466.074 119.532C480.214 97.4842 523.468 82.8731 523.468 82.8731"
            stroke={iconColor}
            stroke-width="25"
            stroke-linecap="round"
          />
          <path
            d="M418.702 15.8932C418.702 15.8932 423.438 61.6681 409.25 83.7914C395.11 105.84 351.855 120.451 351.855 120.451"
            stroke={iconColor}
            stroke-width="25"
            stroke-linecap="round"
          />
          <path
            d="M456.45 187.468C456.45 187.468 441.537 143.933 419.374 129.807C397.286 115.729 351.893 120.622 351.893 120.622"
            stroke={iconColor}
            stroke-width="25"
            stroke-linecap="round"
          />
        </svg> */}

        <Text
          css={{
            color: white ? 'white' : undefined,
            fontWeight: 'bold',
            cursor: 'pointer',
            lineHeight: '20px',
            fontSize: '20px',
          }}
        >
          VINGLA
        </Text>
      </span>
    </Link>
  )
}
