import {
  ActionIcon,
  Badge,
  Button,
  Checkbox,
  Dialog,
  Divider,
  Group,
  HoverCard,
  MantineProvider,
  Menu,
  Paper,
  SimpleGrid,
  Text,
  TextInput,
} from '@mantine/core'
import { useForm } from '@mantine/form'
import { showNotification } from '@mantine/notifications'
import { IconClick, IconCrown, IconLogin, IconLogout, IconMenu, IconRefresh, IconWallet } from '@tabler/icons'
import { AccentButton } from 'components/AccentButton/AccentButton'
import { GrayButton } from 'components/GrayButton/GrayButton'
import { VinglaSupport, linkToTelegraSupport } from 'components/VinglaSupport/VinglaSupport'
import { mqBox } from 'config/mq'
import Head from 'next/head'

import { Favicon } from 'utils/Favicon'
import { VINGLA } from 'utils/VINGLA'
import { cabinetColors, cabinetSizes, cabinetTheme } from 'utils/cabinetTheme'

import { Global } from '@emotion/react'
import { config } from 'config/config'
import Link from 'next/link'
import { useRouter } from 'next/router'
import querystring from 'querystring'
import { useEffect, useRef, useState } from 'react'
import { getPathToFile } from 'utils/getPathToFile'
import { isProduction } from 'utils/isProduction'
import { kopeikiToRubles } from 'utils/kopeikiToRubles'
import { trpc } from 'utils/trpc'

const stringifiedParamsGoogle = querystring.stringify({
  client_id: config.idGoogle,
  redirect_uri: `${config.url}/api/auth/google`,
  scope: ['https://www.googleapis.com/auth/userinfo.email', 'https://www.googleapis.com/auth/userinfo.profile'].join(' '), // space seperated string
  response_type: 'code',
  access_type: 'offline',
  prompt: 'consent',
})

const googleLoginUrl = `https://accounts.google.com/o/oauth2/v2/auth?${stringifiedParamsGoogle}`

const stringifiedParamsYandex = querystring.stringify({
  client_id: config.idYandex,
  redirect_uri: `${config.url}/api/auth/yandex`,
  response_type: 'code',
  state: 'consent',
})

const yandexLoginUrl = `https://oauth.yandex.ru/authorize?${stringifiedParamsYandex}`

export function IsLoggedUserWrap({
  children,
  fullWidth,
  showDocuments,
}: {
  children: JSX.Element
  fullWidth?: boolean
  showDocuments?: boolean
}) {
  const router = useRouter()

  const userLogin = trpc.useMutation('user.login', {
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const userRegistration = trpc.useMutation('user.registration', {
    onSuccess: data => {},
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })
  const userRecovery = trpc.useMutation('user.recovery', {
    onSuccess: data => {
      showNotification({
        title: data,
        message: '',
        color: 'green',
      })
    },
    onError: error => {
      showNotification({
        title: error.message,
        message: '',
        color: 'red',
      })
    },
  })

  const loginUserForm = useForm({
    initialValues: {
      login: '',
      password: '',
    },
  })
  const registrationUserForm = useForm({
    initialValues: {
      mail: '',
      password: '',
    },
  })
  const recoveryUserForm = useForm({
    initialValues: {
      mail: '',
    },
  })

  const [opened, setOpened] = useState(false)

  const appVersionTRPC = trpc.useQuery(['user.getAppVersion'], {
    refetchInterval: 5000,
    onSuccess: data => {
      setAppVersion(appVersion => {
        if (typeof appVersion == 'number' && appVersionTRPC.data !== appVersion) {
          setOpened(true)
        }
        return appVersionTRPC.data
      })
    },
  })
  const [appVersion, setAppVersion] = useState<number | undefined>()

  // useEffect(() => {

  // }, [appVersionTRPC.data])

  const checkUser = trpc.useQuery(['user.userIsLogged'], {
    onError: () => {
      // router.push('/panel')
    },
    notifyOnChangeProps: ['isSuccess', 'data'],
    retry: 0,
    refetchInterval: 5000,
  })

  useEffect(() => {
    if (checkUser.isSuccess) {
      //   showNotification({
      //     title: 'Доброго времени суток ❤️❤️❤️',
      //     message: '',
      //     color: 'green',
      //   })
    } else {
      //   showNotification({
      //     title: 'Вас выкинуло из кабинета 🦾',
      //     message: '',
      //     color: 'red',
      //   })
    }
  }, [checkUser.isSuccess])

  const logoutUser = trpc.useMutation('user.logout')

  const boxHeightRef = useRef<HTMLDivElement>(null)
  const [height, setHeight] = useState(0)

  const barRef = useRef<HTMLDivElement>(null)
  const [barHeight, setBarHeight] = useState(0)

  useEffect(() => {
    if (barRef.current) {
      setBarHeight(barRef.current.offsetHeight)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (barRef.current) setBarHeight(barRef.current.offsetHeight)
      })
      resizeObserver.observe(barRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const documentsHeight = 30

  useEffect(() => {
    if (boxHeightRef.current) {
      setHeight(boxHeightRef.current.offsetHeight)
      const resizeObserver: ResizeObserver = new ResizeObserver(() => {
        if (boxHeightRef.current) setHeight(boxHeightRef.current.offsetHeight)
      })
      resizeObserver.observe(boxHeightRef.current)
      return () => {
        resizeObserver.disconnect()
      }
    }
  }, [])

  const [page, setPage] = useState<'login' | 'registration' | 'recovery'>('login')

  const barWidth = 300

  const links: Array<{ path: string; text: string; count?: number; outside?: true } | undefined> = [
    {
      path: `/panel/`,
      text: 'САЙТЫ',
    },
    checkUser.data
      ? {
          path: `/panel/balance`,
          text: `БАЛАНС`,
          count: kopeikiToRubles(checkUser.data.balance),
        }
      : undefined,
    checkUser.data
      ? {
          path: `/panel/domains`,
          text: `ДОМЕНЫ`,
        }
      : undefined,
    checkUser.data?.partnerOwner
      ? {
          path: `/panel/partner`,
          text: `ПАРТНЕР `,
          count: kopeikiToRubles(checkUser.data.partnerOwner.balance),
        }
      : undefined,
    {
      path: linkToTelegraSupport,
      text: 'ПОДДЕРЖКА',
      outside: true,
    },
  ]

  const accountJSX = checkUser.data ? (
    <Menu withinPortal offset={14} position="bottom-end" width={280} shadow="md">
      <Menu.Target>
        <Group
          css={{
            cursor: 'pointer',
          }}
          spacing={10}
        >
          <Group
            p={6}
            pr={10}
            css={{
              background: 'white',
              borderRadius: 1000,
            }}
            spacing={12}
          >
            <Group
              css={{
                width: 32,
                height: 32,
                borderRadius: 1000,
                background: cabinetColors.black,
                alignItems: 'center',
              }}
            >
              <Text
                css={{
                  textAlign: 'center',
                  display: 'block',
                  width: '100%',
                  borderRadius: 1000,
                }}
                weight={600}
                color={cabinetColors.white}
              >
                {checkUser.data.login.substring(0, 2)}
              </Text>
            </Group>
            <svg
              css={{
                width: 12,
              }}
              width="17"
              height="9"
              viewBox="0 0 17 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1 1L8.50001 6.96681L16 1" stroke="#333333" stroke-width="2" />
            </svg>
          </Group>
        </Group>
      </Menu.Target>
      <Menu.Dropdown
        css={{
          padding: 0,
          background: cabinetColors.white,
          overflow: 'hidden',
        }}
      >
        <Group
          css={{
            cursor: 'pointer',
            '& *': {
              color: cabinetColors.placeholder,
            },
            '&:hover *': {
              color: cabinetColors.black,
            },
          }}
          onClick={() => {
            router.push('/panel/account')
          }}
          p={8}
          spacing={12}
        >
          {checkUser.data.isAdmin ? <IconCrown color={cabinetColors.placeholder} size={cabinetSizes.icon} /> : undefined}
          <Text>{checkUser.data.login}</Text>
        </Group>

        <Divider />

        <Group
          onClick={async () => {
            await logoutUser.mutateAsync()
            checkUser.refetch()
          }}
          p={8}
          css={{
            backgroundColor: cabinetColors.background,
            cursor: 'pointer',
          }}
          spacing={12}
        >
          <IconLogout size={cabinetSizes.icon} />
          <Text>ВЫЙТИ</Text>
        </Group>
      </Menu.Dropdown>
    </Menu>
  ) : (
    <></>
  )

  const menuBodyJSX = checkUser.data ? (
    <Group
      css={{
        boxShadow: 'none',
        border: 'none',
        background: 'white',
        borderRadius: 1000,
        [mqBox.desktop]: {
          boxShadow: '0 4px 5px 0px rgba(0, 0, 0, 0.02)',
          border: '1px solid #EEEEEE',
        },
      }}
      spacing={0}
      p={0}
    >
      {links.map(link => {
        if (link) {
          const bodyLinkJSX = (
            <Text
              css={{
                textDecoration: 'none',
                padding: '10px 20px',
                cursor: 'pointer',
              }}
            >
              {link.text} {typeof link.count == 'number' ? <strong>{`(${link.count})`}</strong> : null}
            </Text>
          )

          if (link.outside)
            return (
              <a
                style={{
                  textDecoration: 'none',
                  color: 'inherit',
                }}
                target="_blank"
                href={link.path}
              >
                {bodyLinkJSX}
              </a>
            )

          return <Link href={link.path}>{bodyLinkJSX}</Link>
        }
        return <></>
      })}
      {!checkUser.data.mailConfirmed ? (
        <Badge radius={2} variant="gradient" gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>
          <>Вам нужно подвердить почту</>
        </Badge>
      ) : null}
    </Group>
  ) : (
    <></>
  )

  return (
    <>
      <Global
        styles={`

          html,
          body {
            font-family: 'Inter', Helvetica, Arial, sans-serif !important;
            overflow-x: hidden !important;
            display: unset;
          }


          html {
            scroll-behavior: smooth;
          }
          
          * {
            padding: 0;
            margin: 0;
          }
          
          #__next {
            display: unset;
          }
          `}
      />

      <MantineProvider withGlobalStyles withNormalizeCSS theme={cabinetTheme({})}>
        <>
          <Dialog opened={isProduction ? opened : false} withCloseButton onClose={() => setOpened(false)} size="md" radius="md">
            <Text size="sm" style={{ marginBottom: 10 }}>
              Доступна новая версия конструктора, нажмите на кнопку "Обновить сайт" чтобы перезагрузить страницу
            </Text>

            <Group align="flex-end">
              <Button
                onClick={() => {
                  setOpened(false)
                  location.reload()
                }}
              >
                Обновить сайт
              </Button>
            </Group>
          </Dialog>

          <style
            dangerouslySetInnerHTML={{
              __html: `html, body {overflow: hidden; height: 100%; position: fixed; top: 0; left: 0; right: 0;} 
          
          
          `,
            }}
          />
          {checkUser.isSuccess && checkUser.data ? (
            <div css={{ height: `100%`, overflow: 'hidden', position: 'fixed', left: 0, top: 0, width: '100%', zIndex: 100 }}>
              {fullWidth ? (
                <>{children}</>
              ) : (
                <>
                  <div
                    css={{
                      display: 'flex',
                      alignItems: 'stretch',
                      height: '100%',
                      flexWrap: 'wrap',
                      overflowY: 'auto',
                      background: cabinetColors.background,
                    }}
                  >
                    <div
                      css={{
                        position: 'relative',
                        zIndex: 1000,
                        borderRight: 'none',
                        height: 'auto',
                        width: '100%',
                        borderBottom: `1px solid ${cabinetColors.close}`,
                      }}
                      ref={barRef}
                    >
                      <Group
                        spacing={8}
                        css={{
                          padding: `8px 12px`,
                          alignItems: 'center',
                          [mqBox.desktop]: {
                            padding: `12px`,
                          },
                        }}
                        position="apart"
                      >
                        <VINGLA />

                        <Group
                          css={{
                            display: 'none',
                            [mqBox.desktop]: {
                              display: 'block',
                            },
                          }}
                          spacing={8}
                        >
                          {menuBodyJSX}
                        </Group>

                        <Group
                          css={{
                            alignItems: 'center',
                          }}
                          spacing={12}
                        >
                          <Link href="/panel/balance">
                            <ActionIcon
                              css={{
                                display: 'block',
                                [mqBox.desktop]: {
                                  display: 'none',
                                },
                              }}
                              size={'sm'}
                              // variant="light"
                            >
                              <IconWallet />
                            </ActionIcon>
                          </Link>
                          {accountJSX}

                          <HoverCard offset={14} position="bottom-start" width={280} shadow="md">
                            <HoverCard.Target>
                              <ActionIcon
                                css={{
                                  display: 'block',
                                  [mqBox.desktop]: {
                                    display: 'none',
                                  },
                                }}
                                size={'sm'}
                                // variant="light"
                              >
                                <IconMenu />
                              </ActionIcon>
                            </HoverCard.Target>
                            <HoverCard.Dropdown>{menuBodyJSX}</HoverCard.Dropdown>
                          </HoverCard>
                        </Group>
                      </Group>
                    </div>
                    <div
                      css={{
                        width: '100%',
                        height: `calc(100% - ${barHeight}px)`,
                        overflowY: 'auto',
                      }}
                    >
                      {!showDocuments ? (
                        children
                      ) : (
                        <>
                          <div style={{ height: `calc(100% - ${documentsHeight}px)`, overflowY: 'auto' }}>{children}</div>
                          <div
                            style={{ height: documentsHeight, overflowY: 'auto' }}
                            css={{
                              borderTop: '1px solid #EFEFEF',
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              alignContent: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <Group position="center">
                              <a
                                css={{
                                  color: 'black',
                                }}
                                target="_blank"
                                href="https://vingla.ru/terms"
                              >
                                Пользовательское соглашение
                              </a>{' '}
                              <a
                                css={{
                                  color: 'black',
                                }}
                                target="_blank"
                                href="https://vingla.ru/privacy"
                              >
                                Политика конфиденциальности
                              </a>{' '}
                              <a
                                css={{
                                  color: 'black',
                                }}
                                target="_blank"
                                href="https://vingla.ru/public-offer"
                              >
                                Публичная оферта
                              </a>
                            </Group>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              css={{
                minHeight: '100vh',
                maxHeight: '100vh',
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'center',
                overflowY: 'auto',
                alignItems: 'flex-start',
                alignContent: 'flex-start',
                flexDirection: 'row',
                background: `url(${getPathToFile({ folderName: '000-service', fileName: 'login-background.jpg' })})`,
                backgroundSize: 'cover',
                padding: 12,
                gap: 8,
              }}
            >
              <Paper p="lg" css={{ maxWidth: '100%', width: 400, margin: '0 auto' }}>
                <SimpleGrid spacing={'lg'} cols={1}>
                  <Group position={'apart'}>
                    <VINGLA />
                    <VinglaSupport />
                  </Group>
                  {/* <a>google</a> */}

                  {page == 'login' ? (
                    <form
                      name="user-login"
                      onSubmit={loginUserForm.onSubmit(async values => {
                        await userLogin.mutateAsync({
                          login: values.login,
                          password: values.password,
                        })
                        checkUser.refetch()
                      })}
                    >
                      <Head>
                        <title>Вход</title>

                        <Favicon />
                      </Head>
                      <SimpleGrid spacing={'lg'} cols={1}>
                        <TextInput
                          required
                          label={'Введите ваш логин'}
                          name="loginPanel"
                          placeholder={'Логин'}
                          {...loginUserForm.getInputProps('login')}
                          onChange={e => {
                            loginUserForm.setValues({
                              login: e.target.value.toLowerCase(),
                            })
                          }}
                          error={userLogin.error?.message}
                        />
                        <TextInput
                          required
                          type="password"
                          label={'Введите ваш пароль'}
                          name="panelPanel"
                          placeholder={'Пароль'}
                          {...loginUserForm.getInputProps('password')}
                        />
                        <AccentButton leftIcon={<IconLogin />} size="lg" loading={userLogin.isLoading} fullWidth type="submit">
                          Войти
                        </AccentButton>
                        <Agreements />
                        <GrayButton
                          onClick={() => setPage('registration')}
                          css={{ textAlign: 'center', cursor: 'pointer' }}
                          size="md"
                          variant="outline"
                          fullWidth
                        >
                          перейти к регистрации
                        </GrayButton>
                      </SimpleGrid>
                    </form>
                  ) : page == 'registration' ? (
                    <form
                      name="user-registration"
                      onSubmit={registrationUserForm.onSubmit(async values => {
                        await userRegistration.mutateAsync({
                          mail: values.mail,
                          password: values.password,
                        })
                        checkUser.refetch()
                      })}
                    >
                      <Head>
                        <title>Регистрация</title>

                        <Favicon />
                      </Head>
                      <SimpleGrid spacing={'lg'} cols={1}>
                        <TextInput
                          required
                          email
                          label={'Введите вашу почту'}
                          placeholder={'Почта'}
                          {...registrationUserForm.getInputProps('mail')}
                          onChange={e => {
                            registrationUserForm.setValues({
                              mail: e.target.value.toLowerCase(),
                            })
                          }}
                          error={userRegistration.error?.message}
                        />
                        <TextInput
                          label={'Придумайте пароль'}
                          autocomplete="new-password"
                          required
                          placeholder={'Пароль'}
                          {...registrationUserForm.getInputProps('password')}
                        />
                        <AccentButton
                          leftIcon={<IconClick />}
                          size="lg"
                          loading={userRegistration.isLoading}
                          fullWidth
                          type="submit"
                        >
                          Зарегистрироваться
                        </AccentButton>
                        <Agreements />
                        <GrayButton
                          onClick={() => setPage('login')}
                          css={{ textAlign: 'center', cursor: 'pointer' }}
                          size="md"
                          variant="outline"
                          fullWidth
                        >
                          перейти к входу
                        </GrayButton>
                      </SimpleGrid>
                    </form>
                  ) : (
                    <form
                      name="user-recovery"
                      onSubmit={recoveryUserForm.onSubmit(async values => {
                        await userRecovery.mutateAsync({
                          mail: values.mail,
                        })
                      })}
                    >
                      <Head>
                        <title>Восстановление доступа</title>

                        <Favicon />
                      </Head>
                      <SimpleGrid spacing={'lg'} cols={1}>
                        <TextInput
                          required
                          email
                          label={'Введите вашу почту'}
                          placeholder={'Почта'}
                          {...recoveryUserForm.getInputProps('mail')}
                          onChange={e => {
                            recoveryUserForm.setValues({
                              mail: e.target.value.toLowerCase(),
                            })
                          }}
                          error={userRecovery.error?.message}
                        />
                        <AccentButton
                          leftIcon={<IconRefresh />}
                          size="lg"
                          loading={userRecovery.isLoading}
                          fullWidth
                          type="submit"
                        >
                          Восстановить доступ
                        </AccentButton>
                        <Agreements />
                        <GrayButton
                          onClick={() => setPage('login')}
                          css={{ textAlign: 'center', cursor: 'pointer' }}
                          size="md"
                          variant="outline"
                          fullWidth
                        >
                          перейти к входу
                        </GrayButton>
                      </SimpleGrid>
                    </form>
                  )}

                  {page == 'login' || page == 'registration' ? (
                    <GrayButton
                      onClick={() => {
                        setPage('recovery')
                      }}
                      css={{ textAlign: 'center', cursor: 'pointer' }}
                      size="md"
                      variant="outline"
                      fullWidth
                      white={true}
                    >
                      восстановить доступ
                    </GrayButton>
                  ) : null}

                  {1 ? (
                    <></>
                  ) : (
                    <>
                      <Divider />

                      <a
                        css={{
                          textAlign: 'center',
                          padding: '4px 12px',
                          background: 'white',
                          border: '1px solid #ddd',
                          borderRadius: 4,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textDecoration: 'none',
                          '&:hover': {
                            background: '#eee',
                          },
                        }}
                        href={googleLoginUrl}
                      >
                        <span
                          css={{
                            fontSize: 14,
                            color: '#333',
                            fontWeight: '700',
                            textDecoration: 'uppercase',
                            marginRight: 8,
                            display: 'block',
                          }}
                        >
                          ВОЙТИ ЧЕРЕЗ
                        </span>

                        <span
                          css={{
                            display: 'block',
                            width: 36,
                            height: 36,
                            borderRadius: 100,
                            overflow: 'hidden',
                            marginRight: 2,
                          }}
                        >
                          <svg
                            css={{
                              display: 'block',
                              width: '100%',
                              height: '100%',
                            }}
                            xmlns="http://www.w3.org/2000/svg"
                            height="24"
                            viewBox="0 0 24 24"
                            width="24"
                          >
                            <path
                              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
                              fill="#4285F4"
                            />
                            <path
                              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
                              fill="#34A853"
                            />
                            <path
                              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
                              fill="#FBBC05"
                            />
                            <path
                              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
                              fill="#EA4335"
                            />
                            <path d="M1 1h22v22H1z" fill="none" />
                          </svg>
                        </span>
                        <span
                          css={{
                            fontSize: 14,
                            color: '#333',
                            fontWeight: '700',
                            textDecoration: 'uppercase',
                          }}
                        >
                          OOGLE
                        </span>
                      </a>
                      <a
                        css={{
                          textAlign: 'center',
                          padding: '4px 12px',
                          background: '#333',
                          borderRadius: 4,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          textDecoration: 'none',
                          '&:hover': {
                            background: '#000',
                          },
                        }}
                        href={yandexLoginUrl}
                      >
                        <span
                          css={{
                            fontSize: 14,
                            color: '#fff',
                            fontWeight: '700',
                            textDecoration: 'uppercase',
                            marginRight: 8,
                            display: 'block',
                          }}
                        >
                          ВОЙТИ ЧЕРЕЗ
                        </span>

                        <span
                          css={{
                            display: 'block',
                            width: 36,
                            height: 36,
                            borderRadius: 100,
                            overflow: 'hidden',
                            marginRight: 2,
                          }}
                        >
                          <svg
                            css={{
                              display: 'block',
                              width: '100%',
                              height: '100%',
                            }}
                            width="44"
                            height="44"
                            viewBox="0 0 44 44"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect width="44" height="44" fill="#FC3F1D" />
                            <path
                              d="M24.7407 33.9778H29.0889V9.04443H22.7592C16.3929 9.04443 13.0538 12.303 13.0538 17.1176C13.0538 21.2731 15.2187 23.6163 19.0532 26.1609L21.3832 27.6987L18.3927 25.1907L12.4667 33.9778H17.1818L23.5115 24.5317L21.3098 23.0671C18.6496 21.2731 17.3469 19.8818 17.3469 16.8613C17.3469 14.2068 19.2183 12.4128 22.7776 12.4128H24.7223V33.9778H24.7407Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span
                          css={{
                            fontSize: 14,
                            color: '#fff',
                            fontWeight: '700',
                            textDecoration: 'uppercase',
                          }}
                        >
                          НДЕКС
                        </span>
                      </a>
                    </>
                  )}
                </SimpleGrid>
              </Paper>
              <Text
                align="center"
                css={{
                  width: '100%',
                }}
                size={'xs'}
              >
                <a
                  css={{
                    textDecoration: 'none',
                    color: '#333',
                  }}
                  href="https://unsplash.com/photos/desert-under-clear-blue-sky-during-daytime-PP8Escz15d8"
                >
                  Photo by Keith Hardy on Unsplash
                </a>
              </Text>
            </div>
          )}

          {/* {isProduction ? (
        <>
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
   m[i].l=1*new Date();
   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}
   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
   (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");

   ym(93513316, "init", {
        clickmap:true,
        trackLinks:true,
        accurateTrackBounce:true,
        webvisor:true
   });`,
            }}
            type="text/javascript"
          ></script>
          <noscript>
            <div>
              <img src="https://mc.yandex.ru/watch/93513316" style={{ position: 'absolute', left: '-9999px' }} alt="" />
            </div>
          </noscript>
        </>
      ) : null} */}
        </>
      </MantineProvider>
    </>
  )
}

function Agreements() {
  return (
    <Checkbox
      color="dark"
      checked={true}
      label={
        <>
          Я принимаю{' '}
          <a
            css={{
              color: 'black',
            }}
            href="https://vingla.ru/privacy"
            target="_blank"
          >
            Политику конфиденциальности
          </a>{' '}
          и{' '}
          <a
            css={{
              color: 'black',
            }}
            href="https://vingla.ru/terms"
            target="_blank"
          >
            Пользовательское соглашение
          </a>
        </>
      }
    />
  )
}
