import { Button, ButtonProps } from '@mantine/core'
import { cabinetColors, cabinetShadows, cabinetSizes } from 'utils/cabinetTheme'

export function GrayButton(props: ButtonProps & { onClick?: () => void; white?: boolean; small?: boolean }) {
  return (
    <Button
      {...props}
      radius={cabinetSizes.radius}
      styles={{
        root: {
          border: `1px solid ${cabinetColors.border}`,
          background: props.white ? cabinetColors.white : cabinetColors.background,
          color: cabinetColors.black,
          boxShadow: cabinetShadows.input,
          height: props.small ? cabinetSizes.smallButtonHeight : cabinetSizes.buttonHeight,
          '&:hover': {
            background: cabinetColors.black,
            color: cabinetColors.background,
          },
          '&[data-loading="true"] svg[fill]': {
            fill: cabinetColors.black,
          },
          '&[data-loading="true"]:hover svg[fill]': {
            fill: cabinetColors.background,
          },
        },
      }}
    ></Button>
  )
}
