import { getPathToFile } from './getPathToFile'

export function Favicon() {
  return (
    <link
      rel="shortcut icon"
      type="image"
      href={getPathToFile({ fileName: 'vingla-logo-blue.svg', folderName: '000-service' })}
    />
  )
}
